body {
  margin: 0;
  font-family: monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #72DDF7;
  overflow-x: hidden;
  background-color: #313131;
  display:grid;
  min-height:100vh;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
footer {
  margin-top:auto;
  width: 100%;
  text-align: center;
  overflow: hidden;
  padding: 10px;
  color: #e5f77d;
}
footer b {
  font-size: 0.6em;
}
footer a {
  text-decoration: none;
}
.contact-icons {
  width: 30px;
  height: auto;
}
.page-container {
  font-size: 0.7em;
}

.float-right {
  float: right;
}

.icon {
  height: 1em;
  width: 1em;
}
.icon-lines {
  fill:none; 
  stroke-width:10;
}
.icon-text {
  height: 1em;
  width: 1em;
  stroke-width:0;
}
.title {
  color: #e5f77d;
  padding-top: 5vh;
  margin-bottom: 5vh;
  background-image: linear-gradient(#222222,#313131);
}

.navbar {
  background-color: #313131;
  background-color: rgba(49, 49, 49, 0.6);
  backdrop-filter: blur(5px);
}
.navbar-nav {
	display: inline-block;
  font-size: 0.9em;
  background-color: #72DDF7;
  border-style: outset;
  border-color: #313131;
  color: #313131;
  stroke: #313131;
  fill: #313131;
}
.nav-item {
	display: inline-block;
}
.nav-link:hover {
  color: #e5f77d;
  stroke: #E5F77D;
  fill: #E5F77D;
}
.current-page {
  background-color: #7D83FF;
  border-style: inset;
  border-color: #313131;
}

hr {
  border-top: 2px solid #72DDF7;
}
.page-container img {
  border: 4px inset #313131;
}

.benji-link {
  color: #313131;
  background-color: #72DDF7;
  border: 3px outset #313131;
  font-weight: bold;
  text-decoration: none;
  font-size: 1em;
  border-radius: 25px 0 25px 0;
}
.benji-link:hover {
  background-color: #e5f77d;
}

.proj-pic {
  min-width: 100%;
}

h4 {
  color: #e5f77d;
}

.item-lists {
  font-size: 0.8em;
}
.item-lists b {
  color: #313131;
  background-color: #2DC887;
  border-radius: 15px;
  width: 100%;
  display: inline-block;
  margin: 6px 0;
  padding: 3px 10px;
}

.content {
  width: 100%;
  list-style: none;
}
.content ul {
  list-style: none;
}
.content li {
  margin-bottom: 4px;
}
.content img {
  float: right;
  max-height: 100px;
  margin: 0 0 15px 15px;
}

/*setting up mobile display*/
.tablet-desktop {
  display: none;
}
.mobile-only {
  display: block;
}  
.desktop-only {
  display: none;
}
/*media query for tablets and mobile only*/
@media screen and (max-width: 769px)
{
  .tablet-mobile {
    display: block;
  }
}
/*media query for tablets and desktop*/
@media screen and (min-width: 563px)
{
  .tablet-desktop {
    display: block;
  }
  .mobile-only {
    display: none;
  }
  .contact-icons {
    width: 50px;
    height: auto;
  }  
  .content img {
    max-height: 150px;
  }
  .page-container {
    font-size: 1em;
  }
  .navbar-nav {
    font-size: 1em;
  }
  footer b {
    font-size: 0.9em;
  }
}
/*media query for desktop only*/
@media screen and (min-width: 769px)
{
  .tablet-mobile {
    display: none;
  }
  .desktop-only {
    display: block;
  }
  .navbar-nav {
    font-size: 1.5em;
  }
  .content img {
    max-height: 200px;
  }
  .benji-link {
    font-size: 1.5em;
  }
}